import { BillingRequestStatusSchema } from 'api/invoices/interfaces/BillingRequestStatus';
import { DataTableColumn } from 'components/DataTable';
import { DynamicFieldType } from 'components/Dynamic/DynamicForm';
import { DynamicQueryFieldType } from 'components/Dynamic/DynamicSearchBox/types';
import { convertDateToUTCFormat, convertUTCDateToString } from 'utils/convertDate';
import { BillingRequestStatusChip } from './components/BillingRequestStatusChip';
import {
  BillingRequestData,
  BillingRequestSearch,
  BillingRequestSearchInitialState,
  BillingRequestStatusMap,
  DefaultBillingRequestStatusSearch,
} from './types';

export const invoiceDetailFields: DataTableColumn<BillingRequestData>[] = [
  { id: 'name', label: 'Name', sortable: true },
  { id: 'number', label: 'Bill Request Number', sortable: true },
  { id: 'arInvoiceNumber', label: 'AR Invoice Number', sortable: true },
  {
    id: 'startPeriod',
    label: 'Billing Period Range',
    sortable: true,
    accessor: (row) =>
      row.billingPeriodRange
        .split(' - ')
        .map((date) => convertUTCDateToString(date))
        .join(' - '),
  },
  { id: 'amount', label: 'Amount' },
  {
    id: 'status',
    label: 'Status',
    accessor: (row) => <BillingRequestStatusChip status={row.status} />,
  },
];

export const invoiceSearchFields = (): DynamicQueryFieldType<BillingRequestSearch>[] => {
  return [
    {
      label: 'Bill Request Number',
      name: 'number',
      searchType: 'like',
      type: DynamicFieldType.TEXT,
      searchFor: 'number',
    },
    {
      label: 'Name',
      name: 'name',
      searchType: 'like',
      type: DynamicFieldType.TEXT,
    },
    {
      label: 'Bill Period Range',
      name: 'billingPeriod',
      searchFor: ['startPeriod', 'endPeriod'],
      searchType: 'onlyDateRange',
      type: DynamicFieldType.RANGE_DATE,
      searchTransformer: (value) => {
        const newValues = (value as string[]).map(convertDateToUTCFormat);
        return newValues;
      },
    },
    {
      label: 'Status',
      name: 'status',
      searchType: 'in',
      type: DynamicFieldType.SELECT,
      select: {
        options: [
          { label: '(all)', value: BillingRequestSearchInitialState.status },
          ...DefaultBillingRequestStatusSearch.map((key) => ({
            label: BillingRequestStatusSchema[key].label,
            value: BillingRequestStatusMap[key].join(','),
          })),
        ],
      },
      searchTransformer: (value) => {
        return value.toString().split(',');
      },
    },
  ];
};
