import { QueryFunction } from './functions';
import { QueryItem } from './item';

export type Selector = string | QueryFunction;
export type Value = string | number;

abstract class Comparator extends QueryItem {
 selector: Selector;
 value?: Value;
 comparatorKeyWord: string;
 constructor(selector: Selector, comparatorKeyWord: string, value?: Value) {
  super();
  if (new.target === Comparator) {
   throw new TypeError(`Cannot construct ${new.target.name} instances directly`);
  }
  this.selector = selector;
  this.comparatorKeyWord = comparatorKeyWord;
  this.value = value;
 }

 toString() {
  if (this.value === undefined) {
   return `${this.selector} ${this.comparatorKeyWord}`;
  }
  if (typeof this.value === 'number') {
   return `${this.selector} ${this.comparatorKeyWord} ${this.value}`;
  }
  // add quotes for string values
  return `${this.selector} ${this.comparatorKeyWord} '${this.value}'`;
 }
}

class Like extends Comparator {
 constructor(selector: Selector, value: Value) {
  super(selector, '~~', value);
 }
}

class Equal extends Comparator {
 constructor(selector: Selector, value: Value) {
  super(selector, ':', value);
 }
}

class NotEqual extends Comparator {
 constructor(selector: Selector, value: Value) {
  super(selector, '!', value);
 }
}

class GreaterThan extends Comparator {
 constructor(selector: Selector, value: Value) {
  super(selector, '>', value);
 }
}

class GreaterThanOrEqual extends Comparator {
 constructor(selector: Selector, value: Value) {
  super(selector, '>:', value);
 }
}

class LessThan extends Comparator {
 constructor(selector: Selector, value: Value) {
  super(selector, '<', value);
 }
}
class LessThanOrEqual extends Comparator {
 constructor(selector: Selector, value: Value) {
  super(selector, '<:', value);
 }
}

class IsNull extends Comparator {
 constructor(selector: Selector) {
  super(selector, 'IS NULL');
 }
}

class IsNotNull extends Comparator {
 constructor(selector: Selector) {
  super(selector, 'IS NOT NULL');
 }
}

class IsEmpty extends Comparator {
 constructor(selector: Selector) {
  super(selector, 'IS EMPTY');
 }
}

class IsNotEmpty extends Comparator {
 constructor(selector: Selector) {
  super(selector, 'IS NOT EMPTY');
 }
}

class In extends Comparator {
 constructor(selector: Selector, ...values: Value[]) {
  super(selector, 'IN', "['" + values.join("', '") + "']");
 }

 toString() {
  return `${this.selector} ${this.comparatorKeyWord} ${this.value}`;
 }
}

export {
 Comparator,
 Like,
 Equal,
 NotEqual,
 GreaterThan,
 GreaterThanOrEqual,
 LessThan,
 LessThanOrEqual,
 IsNull,
 IsNotNull,
 IsEmpty,
 IsNotEmpty,
 In,
};
